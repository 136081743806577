/*Common*/
body, html {
    font-family:Helvetica, microsoft yahei;
    background:#fff;
    font-size:10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
body {
    position:relative;
    margin:0 auto;
    overflow-x:hidden;
    -webkit-overflow-scrolling:touch
}
* {
    -webkit-tap-highlight-color:rgba(255, 0, 0, 0)
}
*, :after, :before{
    box-sizing: border-box;
}
/* 初始化标签在所有浏览器中的margin、padding值 */
abbr, article, aside, blockquote, button, command, dd, details, div, dl, dt, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, input, li, mark, meter, nav, ol, output, p, pre, progress, section, summary, time, ul {
    margin:0;
    padding:0
}
/* 清除特殊标记的字体和字号 */
address, caption, cite, code, em, figcaption, h1, h2, h3, h4, h5, h6, pre, strong, th {
    font-weight:400;
    font-style:normal
}
/* 清除列表样式 */
dd, dl, dt, li, ul, ol {
    list-style:none
}
/* 缩写，图片等无边框 */
fieldset, iframe, img {
    border:0
}
article, aside, figcaption, figure, footer, header, hgroup, nav, section, video {
    display:block
}
/* 链接样式，颜色可酌情修改 */
a:link {}/* 未访问的链接 */
a:visited {}/* 已访问的链接 */
a:hover {}/* 鼠标移动到链接上 */
a:active {}/* 选定的链接 */
del, ins, a { 
    text-decoration:none;
}
/* 取消a标签点击后的虚线框 */
a:link, a:hover, a:visited, a:active, a:focus { 
    text-decoration:none;
} 
/* 设置页面文字等在拖动鼠标选中情况下的背景色与文字颜色 */
a, a:active {
    outline:none
}
/* 链接样式，颜色可酌情修改 */
b, strong {
    font-weight:700
}
input, select, button, textarea{
    outline:none;
    border:0
}
button, input[type=button] {
    font-family:Helvetica, microsoft yahei;
}
input[type=text] {
    background-position-y:-1px;
}
address, cite, em, i, var {
    font-style:normal;
}
/*阻止旋转屏幕时自动调整字体大小*/
textarea {
    resize:none;
}
/*取消按钮在inphone上的默认样式*/
input[type=button] {
    -webkit-appearance:none;
    outline:none;
}
input::-webkit-input-placeholder {
    color:#999;
}
textarea::-webkit-input-placeholder {
    color:#999;
}
input::-webkit-input-speech-button {
    display:none;
}
table {
    border-collapse:collapse;
    border-spacing:0;
}
th {
    text-align:inherit;
}
caption, th {
    text-align:left;
}
sub, sup {
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline;
}
sup {
    top:-0.5em;
}
sub {
    bottom:-0.25em;
}
.clearfix:after {
    display:block;
    clear:both;
    content:"";
    visibility:hidden;
    height:0;
}
.clearfix {
    zoom:1;
}
@media screen and (max-device-width:320px) {
    body {
        -webkit-text-size-adjust:none;
    }
}
@media screen and (max-device-width:480px) {
    body {
        -webkit-text-size-adjust:none;
    }
}
@media only screen and (-webkit-min-device-pixel-ratio:2) {
    body {
        -webkit-text-size-adjust:none;
    }
}
@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
    body {
        -webkit-text-size-adjust:none;
    }
}
@media only screen and (min-device-width:1000px) and (-webkit-min-device-pixel-ratio:1) {
    body, html {
        -webkit-text-size-adjust:none;
        max-width:640px
    }
}
/*Common*/
/*布局*/
.fl {
    float:left;
    display:inline;
}
.fr {
    float:right;
    display:inline;
}
.cb {
    clear:both;
}
.cl {
    clear:left;
}
.cr {
    clear:right;
}
.rel {
    position:relative;
}
.abs {
    position:absolute;
}
.tac {
    text-align:center;
}
.tal {
    text-align:left;
}
.tar {
    text-align:right;
}
.dib {
    display:inline-block;
}
.vab {
    vertical-align:bottom;
}
.vam {
    vertical-align:middle;
}
.vat {
    vertical-align:top;
}
/* 布局 */
/* Mars Live */
.text-nowrap {
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}
.prevent-bold {
    transition:-webkit-transform 0ms;
    -webkit-transform-origin:0 0;
    -webkit-transform:translate(0) translateZ(0);
}
.font36 {
    font-size: 0.48rem; 
}
.font32 {
    font-size: 0.466667rem; 
}
.font28 {
    font-size: 0.373333rem; 
}
.font24 {
    font-size: 0.32rem; 
}
.font20 {
    font-size: 0.266667rem; 
}
.italic {
    font-style: italic;
}
.bold {
    font-weight: bold;
}
.hide {
    display: none;
}
.show {
    display: block;
}
.mt20 {
    margin-top: 0.266667rem;
}
.mt40 {
    margin-top: 0.533333rem;
}
/* Mars Live */